import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios-auth.js'
import router from '../router/index.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    usuario: {
      usuario: null,
      profesionales: [],
      enfermeros: [],
      roles: [],
      tabsHc: [],
      institucion: null
    },
    listaTurnos: [],
    obrasSociales: [],
    horasBloqueoHC: 99999,
    dniPaciente: 0,
    rutaSalidaHC: 0,
    indexProf: 0,
    videollamada: false,
    linkVideoLlamada: '',
    nombreProfesional: '',
    nombrePaciente: '',
    rutaSalidaNuevoPaciente: 0,
    turnosAlHistoricoFecha: ''
  },

  mutations: {
    updateAccessToken: (state, accessToken) => {
      state.token = accessToken;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
    },
    setDni(state, nroDni) {
      state.dniPaciente = nroDni
    },
    setRutaSalidaHC(state, value) {
      state.rutaSalidaHC = value
    },
    setRutaSalidaNuevoPaciente(state, value) {
      state.rutaSalidaNuevoPaciente = value
    },
    setIndexProf(state, value) {
      state.indexProf = value
    },
    setVideollamada(state, value) {
      state.videollamada = value
    },
    setLinkVideollamada(state, value) {
      state.linkVideoLlamada = value
    },
    setNombreProfesional(state, value) {
      state.nombreProfesional = value
    },
    setNombrePaciente(state, value) {
      state.nombrePaciente = value
    },
    saveUserData(state, userData) {
      state.usuario.usuario = userData.usuario
      state.usuario.profesionales = userData.profesionales
      state.usuario.enfermeros = userData.enfermeros
      state.usuario.roles = userData.roles
      state.usuario.tabsHc = userData.tabsHc
      state.usuario.institucion = userData.institucion
      state.token = userData.token
      state.obrasSociales = userData.obrasSociales
      state.horasBloqueoHC = userData.horasBloqueoHC
      state.turnosAlHistoricoFecha = userData.turnosAlHistoricoFecha
      state.indexProf = 0;

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token
    },
    updateUserData(state, userData) {
      state.usuario.profesionales = userData.profesionales
      state.usuario.roles = userData.roles
      state.usuario.enfermeros = userData.enfermeros
      state.obrasSociales = userData.obrasSociales      
    },
    clearAuthData(state) {
      state.usuario.usuario = null
      state.usuario.profesionales = []
      state.usuario.enfermeros = []
      state.usuario.roles = []
      state.usuario.tabsHc = []
      state.usuario.institucion = null
      state.obrasSociales = []
      state.token = null
      state.videollamada = false
      state.linkVideoLlamada = ''
      state.horasBloqueoHC = null
      state.turnosAlHistoricoFecha = ''
      axios.defaults.headers.common['Authorization'] = null
    },
    armarTurnos(state, turnosData) {
      state.listaTurnos = turnosData
    },

  },

  actions: {
    setDni({ commit }, nroDni) {
      commit('setDni', nroDni)
    },
    setIndexProf({ commit }, value) {
      commit('setIndexProf', value)
    },
    setVideollamada({ commit }, value) {
      commit('setVideollamada', value)
    },
    setLinkVideollamada({ commit }, value) {
      commit('setLinkVideollamada', value)
    },
    setNombreProfesional({ commit }, value) {
      commit('setNombreProfesional', value)
    },
    setNombrePaciente({ commit }, value) {
      commit('setNombrePaciente', value)
    },
    setRutaSalidaHC({ commit }, value) {
      commit('setRutaSalidaHC', value)
    },
    setRutaSalidaNuevoPaciente({ commit }, value) {
      commit('setRutaSalidaNuevoPaciente', value)
    },
    login({ commit, dispatch }, authData) {
      axios.get('/Login?', {
        params: {
          usuario: authData.usuario,
          pass: authData.pass
        }
      }).then(function (response) {

        if (response.data == "Usuario Inexistente") {
          dispatch('showAlert', { icon: 'error', title: 'Usuario o Contraseña inválidos', vm: authData.vm })

        }
        else {
          if (response.data == "Usuario Inactivo") {

            dispatch('showAlert', { icon: 'error', title: 'Cuenta Inactiva', vm: authData.vm })

          }
          else {
            const profesionales = response.data.profesionalesAsociados
            const profs = []
            commit('saveUserData', {
              token: response.data.token,
              usuario: response.data.usuario,
              profesionales: response.data.profesionalesAsociados,
              enfermeros: response.data.enfermerosAsociados,
              roles: response.data.roles,
              tabsHc: response.data.tabsHc,
              institucion: response.data.institucion,
              obrasSociales: response.data.obrasSociales,
              horasBloqueoHC: response.data.horasBloqueoHC,
              turnosAlHistoricoFecha: response.data.turnosAlHistoricoFecha
            })

            localStorage.setItem('token', response.data.token)
            localStorage.setItem('usuario', response.data.usuario)
            router.push({ path: '/Home' })
            dispatch('showAlert', { icon: 'success', title: 'Ingreso Exitoso', vm: authData.vm })
          }
        }
      }).catch(function (error) {

      }).then(function () {

      })
    },


    updateSession({ commit,state }, authData) {
      if(authData.usuario == state.usuario.usuario)
      {
      axios.get('/Login/UpdateSession?', {
        params: {
          usuario: authData.usuario,
          idInstitucion: authData.idInstitucion
        }
      }).then(function (response) {

            commit('updateUserData', {
              profesionales: response.data.profesionales,
              enfermeros: response.data.enfermeros,
              roles: response.data.roles,
              obrasSociales: response.data.obrasSociales
            })
               
      }).catch(function (error) {

      }).then(function () {

      })
    }
    },

    showAlert({ }, alertInfo) {
      const Toast = alertInfo.vm.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', alertInfo.vm.$swal.stopTimer)
          toast.addEventListener('mouseleave', alertInfo.vm.$swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: alertInfo.icon,
        title: alertInfo.title
      })
    },
   
    logout({ commit }) {
      commit('clearAuthData');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      router.push({ path: '/' })
    },
    armarTurnos({ commit }, data) {
      this.state.listaTurnos = []
      const idInstitucion = this.state.usuario.institucion.idInstitucion
      axios.get('/Turnos/ArmarTurnos?', {
        params: {
          idProfesional: data.idProfesional,
          fecha: data.fecha,
          idInstitucion: idInstitucion
        }
      }).then(function (response) {

        commit('armarTurnos', response.data)

      }).catch(function (error) {

      })
    },
    setAuth({ commit }) {
      commit('updateAccessToken', localStorage.getItem('token'));
    }
  },

  modules: {
  },
  plugins: [createPersistedState(
    {
      storage: window.localStorage,
    }
  )]
})
