import axios from "axios";

const instance = axios.create({
  //baseURL: 'https://localhost:44315/api'
  baseURL: process.env.VUE_APP_ROOT_API,
  //baseURL: "https://api.sigemed.com.ar/api",
  //baseURL: 'https://apitest.sigemed.com.ar/api'
});

//instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
