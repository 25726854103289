<template>
  <div class="container">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card
        elevation="24"
        max-width="500"
        class="mx-auto"
        color="#385F73"
        dark
        raised
      >
        <v-card-title class="headline layout justify-center mb-3"
          >Instituciones y Profesionales</v-card-title
        >
        <v-card-subtitle class="layout justify-center"
          >Inicio de Sesión</v-card-subtitle
        >
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="usuario"
                prepend-icon="mdi-account"
                :rules="nameRules"
                label="Usuario"
                required
                @keypress.enter="signin"
              ></v-text-field>

              <v-text-field
                v-model="pass"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                :rules="passRules"
                label="Contraseña"
                :type="showPassword ? 'text' : 'Password'"
                required
                @keypress.enter="signin"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="pull-right">
            <v-card-title class="headline layout justify-center pa-0">
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid || loading"
                :loading="loading"
                @click="signin"
                >Acceder</v-btn
              >
            </v-card-title>
            <v-card-title class="headline layout justify-center pb-1 mb-1 mt-3">
              <v-btn
                @click="recuperarPass"
                x-small
                class="ma-2"
                tile
                outlined
                color="success"
              >
                <v-icon left>mdi-lock</v-icon>¿Olvidaste tu contraseña?
              </v-btn>
            </v-card-title>
          </div>
        </v-container>
        <br />
        <RecuperoPass
          :visible="showRecuperoPass"
          @close="showRecuperoPass = false"
        ></RecuperoPass>
      </v-card>
    </v-form>
    <v-card
      elevation="24"
      max-width="500"
      class="mx-auto mt-3"
      color="#385F73"
      dark
    >
      <v-card-title>
        <v-spacer></v-spacer>
        <h5>¿No tienes una cuenta?</h5>
        <v-btn
          color="warning"
          href="https://www.sigemed.com.ar/#/MensajeRegistro"
          small
          class="ml-3"
          >Registrate</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-title>

      <RecuperoPass
        :visible="showRecuperoPass"
        @close="showRecuperoPass = false"
      ></RecuperoPass>
    </v-card>
  </div>
</template>
<style scoped>
.headline {
  font-size: 1.4rem !important;
}
</style>
<script>
import axios from "../axios-auth.js";
import RecuperoPass from "../components/RecuperoPass.vue";
import router from "../router/index.js";
export default {
  name: "Login",

  data: () => ({
    valid: false,
    lazy: false,
    showPassword: false,
    loading: false,
    showRecuperoPass: false,
    usuario: "",
    nameRules: [(v) => !!v || "El usuario es un dato requerido"],
    pass: "",
    passRules: [(v) => !!v || "La contraseña es un dato requerido"],
  }),

  methods: {
    recuperarPass() {
      this.showRecuperoPass = true;
    },
    scrollToTop() {},
    signin() {
      //this.loading = true;
      this.iniciarSesion();
      //this.loading = false;
    },
    iniciarSesion() {
      if (this.valid) {
        const formData = {
          usuario: this.usuario,
          pass: this.pass,
        };
        this.loading = true;
        // this.$store.dispatch("login", {
        //   usuario: formData.usuario,
        //   pass: formData.pass,
        //   vm: this
        // });
        let self = this;
        axios
          .get("/Login?", {
            params: {
              usuario: formData.usuario,
              pass: formData.pass,
            },
          })
          .then(function(response) {
            if (response.data == "Usuario Inexistente") {
              self.showAlert({
                icon: "error",
                title: "Usuario o Contraseña inválidos",
                vm: self,
              });
              self.loading = false;
            } else {
              if (response.data == "Usuario Inactivo") {
                self.showAlert({
                  icon: "error",
                  title: "Cuenta Inactiva",
                  vm: self,
                });
                self.loading = false;
              } else {
                const profesionales = response.data.profesionalesAsociados;
                const profs = [];

                self.$store.commit("saveUserData", {
                  token: response.data.token,
                  usuario: response.data.usuario,
                  profesionales: response.data.profesionalesAsociados,
                  roles: response.data.roles,
                  tabsHc: response.data.tabsHc,
                  institucion: response.data.institucion,
                  obrasSociales: response.data.obrasSociales,
                  horasBloqueoHC: response.data.horasBloqueoHC,
                  turnosAlHistoricoFecha: response.data.turnosAlHistoricoFecha
                });

                localStorage.setItem("token", response.data.token);
                localStorage.setItem("usuario", response.data.usuario);
                self.showAlert({
                  icon: "success",
                  title: "Ingreso Exitoso",
                  vm: self,
                });
                self.loading = false;
                router.push({ path: "/Home" });
              }
            }
          })
          .catch(function(error) {
            self.loading = false;
          });
      }
    },
    showAlert(alertInfo) {
      const Toast = alertInfo.vm.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", alertInfo.vm.$swal.stopTimer);
          toast.addEventListener("mouseleave", alertInfo.vm.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
      });
    },
  },
  created() {
    this.$store.commit("clearAuthData");
  },
  components: {
    RecuperoPass,
  },
};
</script>
