import Login from "../components/Login.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/GestionTurnos",
    name: "GestionTurnos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/GestionTurnos.vue"
      ),
  },
  {
    path: "/GestionTurnosSemana",
    name: "GestionTurnosSemana",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/GestionTurnosSemana.vue"
      ),
  },
  {
    path: "/GestionTurnosSemanaWide",
    name: "GestionTurnosSemanaWide",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/GestionTurnosSemanaWide.vue"
      ),
  },
  {
    path: "/Feriados",
    name: "Feriados",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/Configuraciones/Feriados.vue"
      ),
  },
  {
    path: "/Cronogramas",
    name: "Cronogramas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/Configuraciones/Cronogramas.vue"
      ),
  },
  {
    path: "/Cobros",
    name: "Cobros",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Caja/Cobros.vue"),
  },
  {
    path: "/Liquidacion",
    name: "Liquidacion",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Caja/Liquidacion.vue"
      ),
  },
  {
    path: "/CierreCaja",
    name: "CierreCaja",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Caja/CierreCaja.vue"
      ),
  },
  {
    path: "/Presupuestos",
    name: "Presupuestos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Caja/Presupuestos.vue"
      ),
  },
  {
    path: "/BuscarTurnos",
    name: "BuscarTurnos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/BuscarTurnos.vue"
      ),
  },
  {
    path: "/BuscarTurnosEliminados",
    name: "BuscarTurnosEliminados",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/BuscarTurnosEliminados.vue"
      ),
  },
  {
    path: "/ConfCuenta",
    name: "ConfCuenta",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Cuenta/ConfCuenta.vue"
      ),
  },
  {
    path: "/HistoriasClinicas",
    name: "HistoriasClinicas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/HistoriasClinicas.vue"
      ),
  },
  {
    path: "/HcTabs",
    name: "HcTabs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/HcTabs.vue"
      ),
  },
  {
    path: "/VideoLlamada",
    name: "VideoLlamada",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/VideoLlamada.vue"
      ),
  },
  {
    path: "/Pacientes",
    name: "Pacientes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/Pacientes.vue"
      ),
  },

  {
    path: "/Profesionales",
    name: "Profesionales",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Configuraciones/Profesionales.vue"
      ),
  },
  {
    path: "/Consultorios",
    name: "Consultorios",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Configuraciones/Consultorios.vue"
      ),
  },
  {
    path: "/InstitucionesExternas",
    name: "InstitucionesExternas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Configuraciones/InstitucionesExternas.vue"
      ),
  },
  {
    path: "/Usuarios",
    name: "Usuarios",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Cuenta/Usuarios.vue"
      ),
  },
  {
    path: "/LogoFoto",
    name: "LogoFoto",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Cuenta/LogoFoto.vue"
      ),
  },
  {
    path: "/ObrasSociales",
    name: "ObrasSociales",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Configuraciones/ObrasSociales.vue"
      ),
  },
  {
    path: "/Topes",
    name: "Topes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Turnos/Configuraciones/Topes.vue"
      ),
  },
  {
    path: "/Home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/Recordatorios",
    name: "Recordatorios",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Configuraciones/Recordatorios.vue"
      ),
  },
  {
    path: "/Vacunas",
    name: "Vacunas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Enfermeria/Vacunas.vue"
      ),
  },
  {
    path: "/Enfermeros",
    name: "Enfermeros",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Enfermeria/Enfermeros.vue"
      ),
  },
  {
    path: "/Enfermeria",
    name: "Enfermeria",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Enfermeria/Enfermeria.vue"
      ),
  },
  {
    path: "/TutorialesTurnos",
    name: "TutorialesTurnos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesTurnos.vue"
      ),
  },
  {
    path: "/TutorialesCuenta",
    name: "TutorialesCuenta",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesCuenta.vue"
      ),
  },
  {
    path: "/TutorialesCaja",
    name: "TutorialesCaja",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesCaja.vue"
      ),
  },
  {
    path: "/TutorialesGestion",
    name: "TutorialesGestion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesGestion.vue"
      ),
  },
  {
    path: "/TutorialesHC",
    name: "TutorialesHC",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesHC.vue"
      ),
  },
  {
    path: "/TutorialesConfig",
    name: "TutorialesConfig",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Tutoriales/TutorialesConfiguraciones.vue"
      ),
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Estadisticas/Dashboard.vue"
      ),
  },
  {
    path: "/Plantillas",
    name: "Plantillas",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/PlantillasEstudios.vue"
      ),
  },
  {
    path: "/ListadoInformes",
    name: "ListadoInformes",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/ListadoInformes.vue"
      ),
  },
  {
    path: "/ListadoEvoluciones",
    name: "ListadoEvoluciones",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/HistClin/ListadoEvoluciones.vue"
      ),
  },
  {
    path: "/Traslados",
    name: "Traslados",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Traslados/Traslados.vue"
      ),
  },
  {
    path: "/Choferes",
    name: "Choferes",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Traslados/Choferes.vue"
      ),
  },
  {
    path: "/Reportes",
    name: "Reportes",
    component: () => import("../components/Estadisticas/Reportes.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
