<template>
  <v-app-bar app color="indigo" dark>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click.stop="menu = !menu"></v-app-bar-nav-icon>
      </template>
      <span>Menú</span>
    </v-tooltip>

    <v-navigation-drawer v-model="menu" height="590px" width="320px" app absolute temporary>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="../../../public/AvatarDr.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ usuario }}</v-list-item-title>
            <v-list-item-subtitle>{{ institucion }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 3)"
          prepend-icon="mdi-calendar-clock"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Turnos</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(turno, i) in turnos" :key="i" :to="turno[1]" link>
            <v-list-item-title v-text="turno[0]"></v-list-item-title>
          </v-list-item>
          <v-list-item to="/BuscarTurnosEliminados" link v-if="roles.some((o) => o.idRol === 1)">
            <v-list-item-title v-text="'Ver Turnos Eliminados'"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action v-if="roles.some((o) => o.idRol === 9)" prepend-icon="mdi-calendar-clock">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Turnos</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="/BuscarTurnos" link v-if="roles.some((o) => o.idRol === 9)">
            <v-list-item-title v-text="'Buscar Turnos'"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 3)"
          prepend-icon="mdi-cogs"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Configuraciones</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(conf, i) in confTurnos" :key="i" :to="conf[1]" link>
            <v-list-item-title v-text="conf[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 8)"
          prepend-icon="mdi-account-multiple"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Gestión</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(gest, i) in gestion" :key="i" :to="gest[1]" link>
            <v-list-item-title v-text="gest[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 4)"
          prepend-icon="mdi-hospital"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Historias Clínicas</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(hc, i) in hcs" :key="i" :to="hc[1]" link>
            <v-list-item-title v-text="hc[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 10)"
          prepend-icon="mdi-needle"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Enfermería</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(enf, i) in enfermeria" :key="i" :to="enf[1]" link>
            <v-list-item-title v-text="enf[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 11)"
          prepend-icon="mdi-ambulance"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Traslados</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(tra, i) in traslados" :key="i" :to="tra[1]" link>
            <v-list-item-title v-text="tra[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 5)"
          prepend-icon="mdi-currency-usd"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Caja</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(caja, i) in cajas" :key="i" :to="caja[1]" link>
            <v-list-item-title v-text="caja[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group
          no-action
          v-if="
            (roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 7)) &&
            !this.$store.state.usuario.institucion.esEmpresa
          "
          prepend-icon="mdi-chart-bar"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Estadísticas</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(est, i) in estadisticas" :key="i" :to="est[1]" link>
            <v-list-item-title v-text="est[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action v-if="this.$store.state.usuario.institucion.esEmpresa" prepend-icon="mdi-chart-bar">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Estadísticas</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(est, i) in reportes" :key="i" :to="est[1]" link>
            <v-list-item-title v-text="est[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-group
          no-action
          v-if="roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 6)"
          prepend-icon="mdi-key-variant"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cuenta</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(conf, i) in confs" :key="i" :to="conf[1]" link>
            <v-list-item-title v-text="conf[0]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout()" block>
            <v-icon left>mdi-power-standby</v-icon>
            Cerrar Sesión</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
    <v-divider vertical></v-divider>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-3" icon small v-bind="attrs" v-on="on" to="/Home">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </template>
      <span>Página de Inicio</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" icon x-small v-bind="attrs" v-on="on" @click="cambiarContrasena">
          <v-icon>mdi-wrench</v-icon>
        </v-btn>
      </template>
      <span>Cambiar Contraseña</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" v-bind="attrs" v-on="on" icon small @click="logout">
          <v-icon>mdi-power-standby</v-icon>
        </v-btn>
      </template>
      <span>Cerrar Sesión</span>
    </v-tooltip>
    <v-spacer></v-spacer>
    <span class="app-version">v.{{ $appVersion }}</span>
    <router-link to="/Home">
      <v-img src="../../assets/isologo.png" max-height="50" max-width="150" contain></v-img>
    </router-link>

    <CambiarContrasena
      :visible="showCambiarContrasena"
      :usuario="usuario"
      @close="showCambiarContrasena = false"
    ></CambiarContrasena>
  </v-app-bar>
</template>

<script>
import CambiarContrasena from "../Cuenta/CambiarContrasena.vue";
export default {
  data() {
    return {
      showCambiarContrasena: false,
      menu: false,
      turnos: [
        ["Agenda Diaria", "/GestionTurnos"],
        ["Agenda Semanal (Grilla)", "/GestionTurnosSemanaWide"],
        ["Agenda Semanal (Lista)", "/GestionTurnosSemana"],
        ["Buscar Turnos", "/BuscarTurnos"],
      ],
      confTurnos: [
        ["Cronogramas", "/Cronogramas"],
        ["Feriados y Cancelaciones", "/Feriados"],
        [`Tope Turnos Diarios por ${this.$store.state.usuario.institucion.esEmpresa ? "Sede" : "O.S"}`, "/Topes"],
      ],
      gestion: [
        ["Profesionales", "/Profesionales"],
        [`${this.$store.state.usuario.institucion.esEmpresa ? "Sedes" : "Obras Sociales"}`, "/ObrasSociales"],
        ["Consultorios", "/Consultorios"],
        ["Instituciones Externas", "/InstitucionesExternas"],
        ["Pacientes", "/Pacientes"],
      ],
      hcs: [
        ["Ver Historias Clínicas", "/HistoriasClinicas"],
        ["Gestión de Pacientes", "/Pacientes"],
        ["Plantillas de Pedidos", "/Plantillas"],
        ["Listado de Archivos", "/ListadoInformes"],
        ["Listado de Evoluciones", "ListadoEvoluciones"],
        ["Gestión de Pestañas de HC", "/HcTabs"],
      ],
      cajas: [
        ["Gestión de Movimientos de Caja", "/Cobros"],
        ["Liquidación a Profesionales", "/Liquidacion"],
        ["Cierre de Caja", "/CierreCaja"],
        ["Presupuestos (Tratamientos)", "/Presupuestos"],
      ],
      estadisticas: [["Dashboard", "/Dashboard"]],
      reportes: [["Reportes", "/Reportes"]],
      confs: [
        ["Configurar Institución", "/ConfCuenta"],
        ["Gestión de Usuarios", "/Usuarios"],
        ["Agregar Logo y Foto", "/LogoFoto"],
      ],
      enfermeria: [
        ["Panel de Enfermería", "/Enfermeria"],
        ["Gestión de Vacunas", "/Vacunas"],
        ["Gestión de Enfermeros", "/Enfermeros"],
      ],
      traslados: [
        ["Gestión de Traslados", "/Traslados"],
        ["Gestión de Choferes", "/Choferes"],
      ],
      group: null,
      usuario: null,
      institucion: null,
      roles: [],
      permiteCrearProfesionales: false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    cambiarContrasena() {
      this.showCambiarContrasena = true;
    },
  },
  created() {
    this.usuario = this.$store.state.usuario.usuario.toString().trim();
    this.institucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();
    this.roles = this.$store.state.usuario.roles;
    this.permiteCrearProfesionales = this.$store.state.usuario.institucion.permiteCrearProfesionales;
  },
  components: {
    CambiarContrasena,
  },
};
</script>
<style scoped>
.app-version {
  color: white;
  font-size: 0.75rem;
  margin-right: 1.5rem;
}
</style>
